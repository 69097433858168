'use client';

import { Toaster } from 'react-hot-toast';
import DeployedVersionLabel from '@/components/shared/DeployedVersionLabel';
import ReCaptchaInitializer from '@/components/shared/ReCaptchaInitializer';

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <>
      <ReCaptchaInitializer />
      <DeployedVersionLabel />
      {children}
      <Toaster position="top-center" />
    </>
  );
}
